.nav-logo a i img {
  width: 30px;
  animation: none;
}

.app-sidebar--dark .sidebar-navigation ul li ul li > a {
  padding: 6px 0 6px 2.30769rem;
  height: auto;
  white-space: inherit;
}

.app-sidebar--dark .sidebar-navigation ul li ul li > a span.sidebar-item-label {
  height: auto;
}

.sidebar-icon-indicator {
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
  opacity: .4;
  margin-left: auto;
  width: 18px;
  height: 18px;
}

.sidebar-icon-indicator > * {
  width: 18px !important;
}

.app-sidebar-logo--text b {
  font-family: 'CovesLight', -apple-system, sans-serif;
  letter-spacing: 2px;
  font-size: 25px;
}

.passo-a-passo {
  text-decoration: underline;
  color: #4191ff;
}

.sidebar-menu-modulos {
  text-transform: uppercase;
  font-size: .83333rem;
  padding: 1rem 1.0rem 0.75rem;
  height: auto;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  margin: 0;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
  text-align: left;
}

.sidebar-menu-modulos:hover {
  transform: none !important;
}

.sidebar-menu-modulos-label {
  margin-top: 3px;
  display: inline-block;
}

.sidebar-ger .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-ger ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-ger ul li ul li a:hover:after {
  background: #3C44B1;
}

.sidebar-mfp .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mfp ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mfp ul li ul li a:hover:after {
  background: #BD413D;
}

.sidebar-mcg .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mcg ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mcg ul li ul li a:hover:after {
  background: #2C87F2;
}

.sidebar-mlf .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mlf ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mlf ul li ul li a:hover:after{
  background: #3AB5C7;
}

.sidebar-mcp .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mcp ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mcp ul li ul li a:hover:after{
  background: #37BD5A;
}

.sidebar-mho .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mho ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mho ul li ul li a:hover:after{
  background: #6440AF;
}

.sidebar-mpr .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mpr ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mpr ul li ul li a:hover:after{
  background: #3E6188;
}

.sidebar-box .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-box ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-box ul li ul li a:hover:after{
  background: #7A7B97;
}

.sidebar-connect .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-connect ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-connect ul li ul li a:hover:after{
  background: #F49D03;
}

.sidebar-site .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-site ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-site ul li ul li a:hover:after{
  background: #222533;
}

.sidebar-mic .sidebar-header,
.app-sidebar--dark .sidebar-navigation.sidebar-mic ul li ul li a.active:after,
.app-sidebar--dark .sidebar-navigation.sidebar-mic ul li ul li a:hover:after{
  background: #068373;
}

.app-sidebar--header {
  margin-bottom: 0;
  justify-content: center;
}

[class*="btn-modulo-"] {
  position: absolute;
  z-index: 9;
  right: -15px;
  top: 14px;
  border: 2px solid;
  background: #fff;
  text-align: center;
  transition: 0.5s;
}

.btn-modulo-ger, .btn-modulo-ger:focus > span svg path, .btn-modulo-ger:hover > span svg path {
  fill: #3C44B1 !important;
  color: #3C44B1 !important;
  border-color: #3C44B1 !important;
}

.btn-modulo-mfp, .btn-modulo-mfp:focus > span svg path, .btn-modulo-mfp:hover > span svg path {
  fill: #BD413D !important;
  color: #BD413D !important;
  border-color: #BD413D !important;
}

.btn-modulo-mcg, .btn-modulo-mcg:focus > span svg path, .btn-modulo-mcg:hover > span svg path {
  fill: #2C87F2 !important;
  color: #2C87F2 !important;
  border-color: #2C87F2 !important;
}

.btn-modulo-mlf, .btn-modulo-mlf:focus > span svg path, .btn-modulo-mlf:hover > span svg path {
  fill: #3AB5C7 !important;
  color: #3AB5C7 !important;
  border-color: #3AB5C7 !important;
}

.btn-modulo-mcp, .btn-modulo-mcp:focus > span svg path, .btn-modulo-mcp:hover > span svg path {
  fill: #37BD5A !important;
  color: #37BD5A !important;
  border-color: #37BD5A !important;
}

.btn-modulo-mho, .btn-modulo-mho:focus > span svg path, .btn-modulo-mho:hover > span svg path {
  fill: #6440AF !important;
  color: #6440AF !important;
  border-color: #6440AF !important;
}

.btn-modulo-mpr, .btn-modulo-mpr:focus > span svg path, .btn-modulo-mpr:hover > span svg path {
  fill: #3E6188 !important;
  color: #3E6188 !important;
  border-color: #3E6188 !important;
}

.btn-modulo-connect, .btn-modulo-connect:focus > span svg path, .btn-modulo-connect:hover > span svg path {
  fill: #F49D03 !important;
  color: #F49D03 !important;
  border-color: #F49D03 !important;
}

.btn-modulo-box, .btn-modulo-box:focus > span svg path, .btn-modulo-box:hover > span svg path {
  fill: #7A7B97 !important;
  color: #7A7B97 !important;
  border-color: #7A7B97 !important;
}

.btn-modulo-site, .btn-modulo-site:focus > span svg path, .btn-modulo-site:hover > span svg path {
  fill: #222533 !important;
  color: #222533 !important;
  border-color: #222533 !important;
}

.btn-modulo-mic, .btn-modulo-mic:focus > span svg path, .btn-modulo-mic:hover > span svg path {
  fill: #068373 !important;
  color: #068373 !important;
  border-color: #068373 !important;
}

.app-sidebar--content.shadow-overflow:before {
  background: linear-gradient(180deg,#253052 20%,hsla(0,0%,100%,0));
  height: 1rem;
}

.app-sidebar--content.shadow-overflow:after {
  background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,#253052 80%);
  height: 2rem;
}

.app-sidebar--header .btn-light-dark-mode svg {
  font-size: 26px;
}

.app-sidebar--header .btn-light-dark-mode {
  position: absolute;
  left: 1rem;
}

.app-sidebar--header div.app-sidebar-logo {
  margin-left: 40px;
}

.app-sidebar-collapsed div.app-sidebar-logo {
  /*margin-left: 70px !important;*/
}

.app-sidebar--dark .app-sidebar--header .expand-sidebar-btn {
  box-shadow: none;
}

.userbox-details .btn.em-breve {
  background: #2C87F2;
  color: #FFFFFF;
  font-size: 10px;
  line-height: 12px;
  padding: 5px;
  border-radius: 5px;
  max-height: 100%;
  opacity: 1;
  transition: max-height 0ms 0ms, opacity 600ms 0ms;
}

.userbox-details .btn.em-breve.hidden {
  position: absolute;
  overflow: hidden;
  max-height: 0 !important;
  opacity: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

@media (min-width: 850px) {
  .show-btn {
    display: none !important;
  }
}

@media (max-width: 530px) {
  .app-footer .row {
    display: flex;
    flex-direction: column;
  }

  .powered-by {
    width: 100%;
    margin-bottom: 5px;
  }

  .networks-footer {
    margin-bottom: 10px;
  }

  .app-footer .col:first-child {
    justify-content: center;
    margin: 10px 0;
  }

  .app-footer .col:last-child {
    flex-direction: column;
  }
}

@media (max-width: 590px) {
  .app-sidebar [class*="btn-modulo-"], .dropdown-modulos {
    display: none !important;
  }

  .app-sidebar-mobile-open > .app-sidebar {
    width: 80% !important;
  }

  .app-sidebar--header div.app-sidebar-logo {
    margin-left: 27%;
  }
}

.app-sidebar--dark .app-sidebar--footer .btn:hover {
  height: 100%;
  transform: none !important;
}

.app-sidebar--footer > ul > li {
  display: inline-flex;
  align-items: center;
}

.app-sidebar--footer > ul > li > a {
  display: inline-flex;
  align-items: center;
}

.sidebar-toggle-processos {
  color: hsl(0deg 0% 100% / 50%) !important;
  padding: 0.5rem !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.sidebar-toggle-processos:hover {
  color: hsla(0,0%,100%,.9) !important;
  background: hsla(0,0%,100%,.09) !important;
}

.sidebar-toggle-processos-active {
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sidebar-toggle-processos-active,
.sidebar-toggle-processos-active:hover,
.sidebar-toggle-processos-active:active {
  color: #FFFFFF !important;
  background-color: #4191FF !important;
  border-color: #4191FF !important;
  transform: none !important;
  height: 22px !important;
}

.app-sidebar--content .ps__thumb-y:hover {
  background-color: #374880 !important;
  width: 7px;
}

.logo-calima {
  width: 160px;
}

.logo-calima.mini {
  width: 30px;
}

.app-sidebar .toggle-mobile-sidebar-btn {
  position: absolute;
  right: 15px;
}

.grid-certificado {
  max-height: 50vh;
}
