.full-size-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .50s ease-in-out;
}

.full-size-modal.visible {
    opacity: 1;
}

.full-size-modal .modal-window {
    background-color: #fff;
    border-radius: 40px;
    position: relative;
}

.full-size-modal .modal-window.height-orientation {
    height: 85vh;
}

.full-size-modal .modal-window.width-orientation {
    width: 80vw;
}

.full-size-modal .modal-window button.close-modal {
    position: absolute;
    top: 30px;
    right: 30px;
    background: #253053;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 28px;
    padding: 6px 0 0 0;
    outline: none;
    z-index: 10;
}
