.modal-pesquisa-nps .modal-header {
    border-bottom: none;
}

.modal-pesquisa-nps.modal-done {
    width: 250px;
}

.modal-pesquisa-nps.modal-done h4.done {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    margin: 0;
}

.pesquisa-nps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: -56px;
    box-sizing: border-box;
}

.pesquisa-nps h2 {
    font-size: 22px;
    font-weight: bold;
}

.pesquisa-nps h4 {
    font-size: 18px;
}

.pesquisa-nps .escala {
    margin-top: 10px;
    text-align: center;
}

.pesquisa-nps .escala button {
    color: #FFFFFF;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 20px;
    font-weight: bold;
    margin: 5px 5px;
    outline: none;
    transition: transform .50s;
}

.pesquisa-nps .escala button:hover {
    transform: scale(.85);
}

.pesquisa-nps .box-comentario {
    width: 80%;
    margin-top: 20px;
}

.pesquisa-nps .box-comentario label {
    font-weight: bold;
    font-size: 15px;
}

.pesquisa-nps .box-comentario textarea {
    border: none;
    border-bottom: solid #C0C3CA 1px;
    padding: 0;
    border-radius: 0;
    margin-top: 10px;
}

.pesquisa-nps .box-comentario textarea:focus {
    box-shadow: none;
}

.pesquisa-nps .submit {
    font-size: 18px;
    width: 200px;
    padding: 5px 0;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.pesquisa-nps .responder-depois {
    background: transparent;
    border: none;
    padding: 0;
    color: #2C87F2;
    margin-top: 10px;
    outline: none;
}