.loading-animation {
  height: 20px;
  width: 100%;
  margin: 10px 0;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingBgAnimation;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #e1e1e1 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
}

.loading-animation.max-width {
  max-width: 300px;
}

@keyframes loadingBgAnimation {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
