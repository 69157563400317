.header-search-item-GER::marker,
.header-search-item-GER li::marker {
    color: #3C44B1;
}

.header-search-item-GER a:hover,
.header-search-item-GER a.selected {
    color: #3C44B1 !important;
    border-bottom: 1px solid #3C44B1;
    background-color: #F4F5FD;
}

.header-search-item-MFP::marker,
.header-search-item-MFP li::marker {
    color: #BD413D;
}

.header-search-item-MFP a:hover,
.header-search-item-MFP a.selected {
    color: #BD413D !important;
    border-bottom: 1px solid #BD413D;
    background-color: #F4F5FD;
}

.header-search-item-MCG::marker,
.header-search-item-MCG li::marker {
    color: #2C87F2;
}

.header-search-item-MCG a:hover,
.header-search-item-MCG a.selected {
    color: #2C87F2 !important;
    border-bottom: 1px solid #2C87F2;
    background-color: #F4F5FD;
}

.header-search-item-MLF::marker,
.header-search-item-MLF li::marker {
    color: #3AB5C7;
}

.header-search-item-MLF a:hover,
.header-search-item-MLF a.selected {
    color: #3AB5C7 !important;
    border-bottom: 1px solid #3AB5C7;
    background-color: #F4F5FD;
}

.header-search-item-MCP::marker,
.header-search-item-MCP li::marker {
    color: #37BD5A;
}

.header-search-item-MCP a:hover,
.header-search-item-MCP a.selected {
    color: #37BD5A !important;
    border-bottom: 1px solid #37BD5A;
    background-color: #F4F5FD;
}

.header-search-item-MHO::marker,
.header-search-item-MHO li::marker {
    color: #6440AF;
}

.header-search-item-MHO a:hover,
.header-search-item-MHO a.selected {
    color: #6440AF !important;
    border-bottom: 1px solid #6440AF;
    background-color: #F4F5FD;
}

.header-search-item-MPR::marker,
.header-search-item-MPR li::marker {
    color: #3E6188;
}

.header-search-item-MPR a:hover,
.header-search-item-MPR a.selected {
    color: #3E6188 !important;
    border-bottom: 1px solid #3E6188;
    background-color: #F4F5FD;
}

.header-search-item-CONNECT::marker,
.header-search-item-CONNECT li::marker {
    color: #F49D03;
}

.header-search-item-CONNECT a:hover,
.header-search-item-CONNECT a.selected {
    color: #F49D03 !important;
    border-bottom: 1px solid #F49D03;
    background-color: #F4F5FD;
}

.header-search-item-BOX::marker,
.header-search-item-BOX li::marker {
    color: #7A7B97;
}

.header-search-item-BOX a:hover,
.header-search-item-BOX a.selected {
    color: #7A7B97 !important;
    border-bottom: 1px solid #7A7B97;
    background-color: #F4F5FD;
}

.header-search-item-SITE::marker,
.header-search-item-SITE li::marker {
    color: #222533;
}

.header-search-item-SITE a:hover,
.header-search-item-SITE a.selected {
    color: #222533 !important;
    border-bottom: 1px solid #222533;
    background-color: #F4F5FD;
}

.header-search-item-MIC::marker,
.header-search-item-MIC li::marker {
    color: #068373;
}

.header-search-item-MIC a:hover,
.header-search-item-MIC a.selected {
    color: #068373 !important;
    border-bottom: 1px solid #068373;
    background-color: #F4F5FD;
}

.header-search-item .fav-toggle {
    visibility: hidden;
    margin-left: 5px;
    font-size: 13px;
    height: 20px;
}

.header-search-item a:hover .fav-toggle,
.header-search-item a.selected .fav-toggle {
    visibility: visible;
}
