.modal-mensageria {
  width: 820px;
  max-width: none;
}

.modal-mensageria .modal-header,
.modal-mensageria .modal-header .close{
  padding-bottom: 0px;
}

.modal-mensageria .modal-body {
  padding: 0px;
}

.modal-mensageria .modal-body iframe {
  height: 432px;
  width: 100%;
  border: 0;
}

.modal-mensageria .modal-body .row-checkbox {
  margin: 0;
}

@media screen and (max-width: 820px) {
  .modal-mensageria {
    width: 95%;
  }
}
