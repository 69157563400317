.modal-sidebar-version .close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 40px;
  line-height: 0;
  padding-top: 0.8rem;
}

.modal-sidebar-version .container-header {
  max-width: 880px;
  width: calc(100% - 24px);
  margin: 0 auto;
}

.modal-sidebar-version .container-header .title {
  font-size: 54px;
  font-weight: bold;
}

.modal-sidebar-version .container-header .container-versao {
  align-items: flex-end;
}

.modal-sidebar-version .info {
  color: #2C87F2;
}

.modal-sidebar-version .container-header .external-link {
  font-weight: bold;
  color: #ABABB1;
  text-decoration: underline;
}

.modal-sidebar-version .container-header .divider {
  height: 2px;
}

.modal-sidebar-version .container-frame {
  height: 63vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-sidebar-version .container-frame .container-loader {
  margin-top: -60px;
}

.modal-sidebar-version .container-frame .container-loader > div {
  width: 130px;
  height: 130px;
}

.modal-sidebar-version .container-frame .container-error {
  font-size: 48px;
  color: #F1F1FF;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background: #3C44B1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 50px;
}

@media screen and (max-width: 1365px) {
  .modal-sidebar-version .container-header .title {
    text-align: center;
  }

  .modal-sidebar-version .container-header > div {
    align-items: center !important;
    flex-direction: column;
  }

  .modal-sidebar-version .container-header .container-versao {
    align-items: center;
  }
}

@media screen and (max-width: 1099px) {
  .modal-sidebar-version .container-header .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 900px) {
  .modal-sidebar-version .container-header {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
}

@media screen and (max-width: 462px) {
  .modal-sidebar-version .container-header .title {
    font-size: 34px;
  }
}
