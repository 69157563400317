.popover-notification .popover-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  background: #f4f5fd;
  border-radius: 0.29rem;
}

.popover-notification .tab-content {
  height: calc(100% - 370px);
  overflow-x: hidden;
  overflow-y: auto;
}

.popover-custom-xl.popover-notification>.popover {
  height: 90%;
}

#toggle-processos {
  color: #3b3e66;
}

.bg-notification {
  background: #253053;
}

.btn-clear-notifications,
.btn-clear-notifications:hover,
.btn-clear-notifications:active {
  color: #FFFFFF !important;
  background-color: #253053 !important;
  border-color: #253053 !important;
}

.btn-toggle-processos {
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-toggle-processos,
.btn-toggle-processos:hover,
.btn-toggle-processos:active {
  color: #FFFFFF !important;
  background-color: #4191FF !important;
  border-color: #4191FF !important;
}

.btn-toggle-downloads,
.btn-toggle-downloads:hover,
.btn-toggle-downloads:active {
  color: #FFFFFF !important;
  background-color: #4191FF !important;
  border-color: #4191FF !important;
}

.notification-footer-container,
.notification-actions-container {
  border-top: 1px solid rgba(122,123,151,.3);
}

.notification-tabs {
  position: relative;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.notification-tabs label {
  text-align: center;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
}

.notification-tabs label div {
  color: #253053;
  transition: 0.6s ease;
}

.notification-tabs #processos:checked ~ label.processos div,
.notification-tabs #mensagens:checked ~ label.mensagens div,
.notification-tabs #tickets:checked ~ label.tickets div {
  color: #FFFFFF;
}

.notification-tabs .tab {
  position: absolute;
  height: 100%;
  width: 33%;
  left: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 14px;
  background: #253053;
  transition: 0.6s ease;
}

.notification-tabs #mensagens:checked ~ .tab {
  left: 33%;
}

.notification-tabs #tickets:checked ~ .tab {
  left: 67%;
}

.notification-tabs input {
  display: none;
}

.notification-tabs .badge-total {
  position: absolute;
  top: 7px;
  margin-left: 5px;
  font-size: 70%;
  color: #fff;
  width: 23px;
  height: 23px;
  background-color: #4191FF;
  padding-top: 4px;
}

.remove-notification {
  background-color: #7A7B97;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 13px;
}

.remove-notification svg path {
  fill: #FFFFFF;
}

.text-notification-deleted {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.card-notification {
  box-shadow: none !important;
  max-height: 74vh;
  opacity: 1;
  transition: max-height 0ms 0ms, opacity 600ms 0ms;
}

.card-notification.hidden {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important;
  transition: max-height 0ms 400ms, opacity 400ms 0ms;
}

@media (max-width: 580px) {
  .popover-notification > .popover {
    width: calc(100% - 18px);
  }
}

@media (max-width: 460px) {
  .notification-tabs .badge-total {
    margin-left: -5px;
    top: 5px;
  }
}
