.nav-neutral-GER, .text-GER {
    color: #3C44B1;
}

.nav-neutral-MFP, .text-MFP {
    color: #BD413D;
}

.nav-neutral-MCG, .text-MCG {
    color: #2C87F2;
}

.nav-neutral-MLF, .text-MLF {
    color: #3AB5C7;
}

.nav-neutral-MCP, .text-MCP {
    color: #37BD5A;
}

.nav-neutral-MHO, .text-MHO {
    color: #6440AF;
}

.nav-neutral-MPR, .text-MPR {
    color: #3E6188;
}

.nav-neutral-CONNECT, .text-CONNECT {
    color: #F49D03;
}

.nav-neutral-BOX, .text-BOX {
    color: #7A7B97;
}

.nav-neutral-SITE, .text-SITE {
    color: #222533;
}

.nav-neutral-MIC, .text-MIC {
    color: #068373;
}