.modal-habilitar-automatizacao-icon {
  width: 50px;
}

.btn-habilitar-agora,
.btn-habilitar-mais-tarde {
  width: 255px;
}

.btn-habilitar-mais-tarde {
  background: #E4E4E4 !important;
  color: #F83245 !important;
}

.btn-habilitar-mais-tarde:hover {
  background: #CDCDCD !important;
}

.modal-habilitar-automatizacao-footer {
  background: #253053;
  color: #FFFFFF;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease-out;
  border: 0 !important;
}

.modal-habilitar-automatizacao-footer:hover {
  background: #3C44B1;
}
