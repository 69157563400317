.tour-favoritos h5 {
    color: #FDFDFF;
    font-size: 16px;
    font-weight: bold;
}

.tour-favoritos p {
    font-size: 14px;
    color: #FDFDFF;
}

.tour-favoritos button {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #FDFDFF;
}

body .tour-favoritos.fade.show div.popover.show {
    background-color: #253053 !important;
    box-shadow: none !important;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 15px 15px 0 15px;
    min-width: 310px;
}

body .tour-favoritos.fade.show div.popover.show .popover-inner {
    background-color: inherit;
    border: none;
    position: relative;
}

body .tour-favoritos .bs-popover-auto[x-placement^=bottom] > span.arrow:after {
    border-bottom-color: #253053 !important;
}

body .tour-favoritos .bs-popover-auto[x-placement^=right] > span.arrow:after {
    border-right-color: #253053 !important;
}
