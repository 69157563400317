.button-calima-pro {
    display: inline-block;
    border: none;
    color: #fff;
    outline: none;
    padding: 10px 0;
    border-radius: 20px;
    margin: 10px 0;
    text-align: center;
    background-image: linear-gradient(180deg, #004996 39%, #4C6E9F 100%);
    width: 100%;
}

.button-calima-pro:hover {
    background-image: linear-gradient(120deg, #004996 39%, #4C6E9F 100%);
}

.button-calima-pro:active, .button-calima-pro:focus {
    outline: none;
}

.button-calima-pro img {
    margin-left: 5px;
    width: 87px;
}
