.modal-certificado-vencido.modal-window {
  background-color: transparent;
  aspect-ratio: 1.5399;
}

.modal-certificado-vencido .slide {
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 86%;
}

.modal-certificado-vencido .slide img {
  width: 100%;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity .75s;
  z-index: 1;
}

.modal-certificado-vencido .slide img.visible {
  opacity: 1;
}

.radio-modal {
  border-radius: 15px !important;
}

.modal-certificado-vencido .area {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
}

.modal-certificado-vencido .slide .area > div {
  background: rgba(217, 217, 217, 0.75);
  border-radius: 15px;
  color: #253053;
  font-size: 40px;
  margin: 0 30px;
  width: 50px;
  height: 55px;
}

.modal-certificado-vencido .ad-footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  height: 14%;
  justify-content: space-between;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #B1B1B1 100%);
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
}

.modal-certificado-vencido .ad-footer .button-link {
  color: #253053;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  vertical-align: top;
  width: 60%;
}

.modal-certificado-vencido .ad-footer .button-link:hover {
  color: #2A79D7;
}

.modal-certificado-vencido .ad-footer .button-contratar {
  background: linear-gradient(180deg, #3c44b1 39%, #0011ff 100%);
  border: none;
  color: #ffffff;
  font-size: 20px;
  padding: 7px 30px;
  border-radius: 15px;
  outline: none;
}

@media screen and (max-width: 820px) {

  .modal-certificado-vencido .ad-footer .button-link {
    font-size: 12px;
  }

  .modal-certificado-vencido .ad-footer .button-contratar {
    font-size: 14px;
    padding: 5px 20px;
  }
}

@media screen and (max-width: 620px) {
  .modal-certificado-vencido .ad-footer .button-link {
    font-size: 10px;
  }

  .modal-certificado-vencido .ad-footer .button-contratar {
    font-size: 10px;
    padding: 5px 20px;
  }
}
