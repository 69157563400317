.modal-wellcome-calima-pro.modal-window {
    aspect-ratio: 1.7769;
    border-radius: 60px;
    background: transparent;
    overflow: hidden;
}

.modal-wellcome-calima-pro.modal-window button.close-modal {
    background: #EEEEEE;
    color: #044B97;
    padding: 3px 0 0 2px;
    box-shadow: rgba(0, 0, 0, .25) 0 4px 4px;
}

.modal-wellcome-calima-pro .slide {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.modal-wellcome-calima-pro .slide .image {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: opacity .75s;
    z-index: 1;
    height: 100%;
    border-radius: 60px;
    background-size: 100% 100%;
}

.modal-wellcome-calima-pro .slide .image.visible {
    opacity: 1;
}

.modal-wellcome-calima-pro .slide button.area {
    position: absolute;
    width: 200px;
    height: 100%;
    z-index: 2;
    border: none;
    outline: none;
    max-width: 50%;
    background: transparent;
}

.modal-wellcome-calima-pro .slide button.area.prev {
    left: 0;
    border-bottom-left-radius: 60px;
    border-top-left-radius: 60px;
}

.modal-wellcome-calima-pro .slide button.area.next {
    right: 0;
    border-bottom-right-radius: 60px;
    border-top-right-radius: 60px;
}

.modal-wellcome-calima-pro .slide .nav-wrapper {
    position: absolute;
    width: 100%;
    bottom: 40px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.modal-wellcome-calima-pro .slide .nav {
    background-image: linear-gradient(90deg, #094D98 80%, #40689E 100%);
    box-shadow: rgba(0, 0, 0, .25) 0 4px 4px;
    border-radius: 20px;
}

.modal-wellcome-calima-pro .slide .nav .item {
    background: #abbed7;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    margin: 10px 5px;
    cursor: pointer;
    transition: background .30s;
}

.modal-wellcome-calima-pro .slide .nav .item.active {
    background: #FEFEFE;
}

.modal-wellcome-calima-pro .slide .nav button {
    border: none;
    background: transparent;
    color: #fff;
    font-size: 18px;
    padding: 5px 10px 3px 10px;
    outline: none;
}

.modal-wellcome-calima-pro .slide .nav button.arrow-right {
    margin-left: 20px;
}

.modal-wellcome-calima-pro .slide .nav button.arrow-left {
    margin-right: 20px;
}

.modal-wellcome-calima-pro .button-calima-pro {
    width: auto;
    font-size: 20px;
    padding: 10px 40px;
    z-index: 1;
    position: absolute;
    left: 8%;
    bottom: 21%;
    opacity: 0;
    display: none;
    transition: opacity .75s;
}

.modal-wellcome-calima-pro .button-calima-pro.visible {
    display: block;
    opacity: 1;
}

.modal-wellcome-calima-pro .button-calima-pro img {
    width: 110px;
}

@media screen and (max-width: 1300px) {
    .modal-wellcome-calima-pro .button-calima-pro {
        font-size: 15px;
    }

    .modal-wellcome-calima-pro .button-calima-pro img {
        width: 90px;
    }
}

@media screen and (max-width: 1100px) {
    .modal-wellcome-calima-pro .button-calima-pro {
        bottom: 18%;
    }
}

@media screen and (max-width: 910px) {
    .modal-wellcome-calima-pro.modal-window button.close-modal {
        font-size: 20px;
        width: 40px;
        height: 40px;
        right: 20px;
        top: 20px;
    }

    .modal-wellcome-calima-pro .slide .nav .item {
        width: 10px;
        height: 10px;
        margin: 6px 5px;
    }

    .modal-wellcome-calima-pro .slide .nav button {
        font-size: 10px;
    }

    .modal-wellcome-calima-pro .slide .nav-wrapper {
        bottom: 10px;
    }

    .modal-wellcome-calima-pro .button-calima-pro {
        bottom: 13%;
    }
}

@media screen and (max-width: 650px) {
    .modal-wellcome-calima-pro .button-calima-pro {
        bottom: 11%;
        font-size: 12px;
    }
}