.modal-propaganda {
  width: 820px;
  max-width: none;
}

.modal-propaganda .modal-header,
.modal-propaganda .modal-header .close{
  padding-bottom: 0px;
}

.modal-propaganda .modal-body {
  padding: 0px;
}

.modal-propaganda .modal-body iframe {
  height: 365px;
  width: 100%;
  border: 0;
  overflow: hidden;
}

.modal-propaganda .modal-body .modal-propaganda-alert {
  margin: 12px 12px 0;
  cursor: pointer;
  font-size: 18px;
}

.modal-propaganda .modal-body .modal-propaganda-alert:hover {
  text-decoration: underline;
}

.modal-propaganda .frame-wrapper {
  position: relative;
}

.modal-propaganda .frame-wrapper .frame-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
}

@media screen and (max-width: 820px) {
  .modal-propaganda {
    width: 95%;
  }
}
