.modulosMobile {
  display: inline-block;
}

.popover-modulos > div {
  transform: none !important;
  max-width: none !important;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: #F1F2FB;
  border: 0;
  display: flex !important;
  position: fixed !important;
}

.popover-modulos > div > div {
  display: flex !important;
}

@media (min-width: 581px) {
  .modulosMobile {
    display: none;
  }
  .popover-modulos {
    display: none;
  }
}

