[class*="btn-menu-"] > div > div {
  color: #335275;
}

.dropdown-modulos {
  transform: translate3d(5px, 63px, 0px) !important;
}

.dropdown-mega-menu-md .btn.card-box:hover:not(.disabled-pro) {
  transform: scale(0.9) !important;
}

.dropdown-mega-menu-md .btn.card-box:not(.disabled-pro):hover > div{
  filter: brightness(100);
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box.disabled-pro:hover {
  transform: scale(1);
  background: #fff !important;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box {
  position: relative;
  overflow: hidden;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box .calima-pro {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(180deg, #004996 40%, #4C6E9F 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity .5s;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box .calima-pro span {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  position: absolute;
  top: 40px;
  transition: top .5s;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box .calima-pro img {
  width: 110px;
  position: absolute;
  top: 48px;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box.disabled-pro:hover .calima-pro {
  opacity: 1;
}

.button-menu-modulo:not(.button-menu-mobile) .btn.card-box.disabled-pro:hover .calima-pro span {
  top: 24px;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-ger:hover, .button-menu-mobile > div > .btn-menu-ger {
  background: #3d44b1 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mfp:hover, .button-menu-mobile > div > .btn-menu-mfp {
  background: #bd413d !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mcg:hover, .button-menu-mobile > div > .btn-menu-mcg {
  background: #2b87f2 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mlf:hover, .button-menu-mobile > div > .btn-menu-mlf {
  background: #3bb4c7 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mcp:hover, .button-menu-mobile > div > .btn-menu-mcp {
  background: #38bd5a !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mpr:hover, .button-menu-mobile > div > .btn-menu-mpr {
  background: #3e6188 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mho:hover, .button-menu-mobile > div > .btn-menu-mho {
  background: #6440af !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-connect:hover, .button-menu-mobile > div > .btn-menu-connect {
  background: #f49d04 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-box:hover, .button-menu-mobile > div > .btn-menu-box {
  background: #7a7b97 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-site:hover, .button-menu-mobile > div > .btn-menu-site {
  background: #222533 !important;
}

.dropdown-mega-menu-md .btn.card-box.btn-menu-mic:hover, .button-menu-mobile > div > .btn-menu-mic {
  background: #068373 !important;
}

.bg-menu-modulos {
  background: #253053;
  border-radius: 24px;
}

.btn.card.card-box {
  border-radius: 24px;
}

.dropdown-modulos {
  margin-left: 13px !important;
  left: 100% !important;
  top: -74px !important;
}

.dropdown [class*="btn-modulo-"]:focus {
  background: #fff !important;
}

.dropdown.show [class*="btn-modulo-"], .dropdown.show [class*="btn-modulo-"]:focus, .dropdown.show [class*="btn-modulo-"]:hover {
  transform: rotate(180deg);
}

.dropdown-mega-menu-md {
  overflow-y: auto !important;
}

.dropdown-mega-menu-md::-webkit-scrollbar {
  width: 7px !important;
}

.dropdown-mega-menu-md::-webkit-scrollbar-thumb {
  background-color: rgba(218, 218, 218, 0.47);
  border-radius: 10px;
}

.dropdown-mega-menu-md::-webkit-scrollbar-track {
  background-color: #00000029;
  border-radius: 10px;
}

.dropdown-mega-menu-md::-webkit-scrollbar-thumb:hover {
  background-color: rgba(138, 138, 138, 0.7);
}

@media (max-width: 590px) {
  .button-menu-modulo{
    width: 33.33% !important;
    text-align: -webkit-center;
  }
  .menu-mobile-name {
    display: inline-block;
    color: #232533;
  }
  .menu-descktop-name {
    display: none;
  }
  [class*="btn-menu-"]{
    width: 85px !important;
    border-radius: 100% !important;
    height: 85px !important;
    justify-content: center;
  }
  [class*="btn-menu-"] > div > img {
    filter: brightness(100);
  }
  [class*="btn-menu-"] > div > svg {
    filter: brightness(100);
  }
  .dropdown-mega-menu-md {
    width: 100vw !important;
    box-sizing: border-box !important;
    margin-top: 26%;
    display: flex;
    padding: 8px;
  }
  .dropdown-mega-menu-md > div .divider {
    background: #DDDDDD !important;
  }
  .bg-menu-modulos {
    border-radius: 0;
    padding: 0 !important;
    transform: translate(-50%,0) !important;
    height: 100vh;
    width: 100vw !important;
    margin-top: 69px !important;
  }
}

@media (min-width: 590px) {
  .button-menu-modulo{
    width: 50% !important;
    padding: 0.5rem !important;
  }
  .menu-mobile-name {
    display: none;
  }
}

.modulo-desabilitado {
  cursor: not-allowed;
}

.modulo-desabilitado > div {
  pointer-events: none;
  opacity: .6;
  filter: grayscale(100%);
}

@media (max-height: 768px) and (min-width: 581px)  {
  .dropdown-mega-menu-md {
    height: 80vh;
  }

  .button-menu-modulo {
    padding: 4px !important;
  }

  .tag-pro-menu {
    padding-top: 0 !important;
  }

  .button-menu-modulo:not(.button-menu-mobile) .btn.card-box {
    padding: 6px 8px !important;
  }

  .dropdown-mega-menu-md {
    width: 23rem;
  }

  .dropdown-mega-menu-md .divider {
    margin-top: 0 !important;
    margin-bottom: 1px !important;
  }

  .dropdown-modulos {
    padding: 14px !important;
  }
}

.button-menu-mobile .badge-pro {
  position: absolute;
  width: 40px;
  bottom: -5px;
}

.disabled-pro {
  position: relative;
}
