.modal-retrospectiva {
  width: 820px;
  max-width: none;
}

.modal-retrospectiva .modal-header .close {
  margin-top: 3px;
  margin-right: 0px;
  padding: 0;
}

.modal-retrospectiva .modal-body img {
  width: 100%;
  cursor: pointer;
}

.retrospectiva-iframe-window {
  position: fixed;
  background: #eeeef0;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  transition: width .50s, height .50s, left .50s, top .50s;
  z-index: 2000;
}

.retrospectiva-iframe-window.frameOpen {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.retrospectiva-iframe-window iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.retrospectiva-iframe-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  font-size: 24px;
  color: #FFF;
  background-color: #E66C5D;
  border-radius: 30px;
  line-height: 27px;
  padding: 7px 25px;
  cursor: pointer;
  outline: none;
  font-weight: 300;
}

.retrospectiva-iframe-close .icon {
  font-size: 24px;
  background: #FFF;
  color: #E66C5D;
  padding: 0 8px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  border-radius: 3px;
  margin-left: 5px;
}
