.modal-block-access.modal-window {
    background-color: transparent;
    aspect-ratio: 1.5399;
}

.modal-block-access .slide {
    width: 100%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 86%;
}

.modal-block-access .slide img {
    width: 100%;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    position: absolute;
    top: 0;
    transition: opacity .75s;
    z-index: 1;
}

.modal-block-access .ad-footer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    height: 14%;
    justify-content: space-between;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #B1B1B1 100%);
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
}

.modal-block-access .ad-footer a {
    color: #0616F8;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
}

.modal-block-access .ad-footer button {
    background: linear-gradient(180deg, #3c44b1 39%, #0011ff 100%);
    border: none;
    color: #ffffff;
    font-size: 24px;
    padding: 7px 30px;
    border-radius: 15px;
    outline: none;
}

@media screen and (max-width: 820px) {
    .modal-block-access .ad-footer button {
        font-size: 20px;
        padding: 5px 20px;
    }
}

@media screen and (max-width: 620px) {
    .modal-block-access .ad-footer a {
        font-size: 14px;
    }

    .modal-block-access .ad-footer button {
        font-size: 16px;
        padding: 5px 20px;
    }
}
