.badge-integra-contador {
  font-size: 13px;
  padding: 2px 8px;
  color: #FFFFFF;
  border-radius: 4px;
  text-wrap: nowrap;
}

.badge-color-dark {
  color: #525252 !important;
}
