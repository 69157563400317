.notfound-container {
  display: flex;
  align-items: center;
  margin-top: 24vh;
}

@media (max-width: 750px) {
  .notfound-container {
    margin-top: 5vh;
  }
}
