.quick-access .active {
    color: #2C87F2;
}

.quick-access-popover > .popover {
    max-width: 935px;
}

.quick-access-popover .empty {
    color: #3B3E66;
    font-size: 16px;
    margin: 0;
    padding: 30px;
}

.quick-access-popover .popover-inner .items {
    display: flex;
    flex-wrap: wrap;
}

.quick-access-popover .popover-inner .items.single .list-item {
    min-width: 390px;
}

.quick-access-popover .list-item .ps__rail-y {
    opacity: 0.6;
}

.quick-access-popover .list-item:nth-child(n+4) {
    border-top: solid 2px #e4e5f0;
}

.quick-access-popover .list-item:not(:nth-child(3n)) {
    border-right: solid 2px #e4e5f0;
}

.quick-access-popover .nav-link.selected {
    background-color: #F4F5FD;
}

.quick-access-popover .list-item .scrollbar-container {
    max-height: 24vh;
}

.quick-access-popover footer {
    width: 100%;
    height: 50px;
    border-top: solid 2px #e4e5f0;
    box-sizing: border-box;
    padding: 11px 10px 10px;
    text-align: right;
    color: #253053;
    font-size: 15px;
}

.quick-access-popover footer span {
    color: #253053;
}

.quick-access-popover footer button {
    background: transparent;
    border: none;
    outline: none;
    font-weight: bold;
    color: #253053;
    text-decoration: underline;
}

.quick-access-popover footer button svg {
    font-size: 13px;
    margin-left: 3px;
    vertical-align: top;
    margin-top: 5px;
}

@media (max-width: 960px) {
    .quick-access > div >:first-child {
        margin: -3px !important;
    }
    .quick-access > div > span {
        display: none;
    }
    .quick-access-popover > .popover {
        width: 622px;
    }
    .quick-access-popover .list-item:not(:nth-child(3n)) {
        border: none;
    }
    .quick-access-popover .list-item:not(:nth-child(2n)) {
        border-right: solid 2px #e4e5f0;
    }
}

@media screen and (max-width: 640px) {
    .quick-access-popover > .popover {
        width: 312px;
    }
    .quick-access-popover .list-item:not(:nth-child(3n)) {
        border: none;
    }
    .quick-access-popover .list-item:not(:nth-child(2n)) {
        border: none;
    }
}

@media (min-width: 581px) {
    .quick-access:hover > div {
        color: #3c44b1;
    }

    .quick-access {
        transform: none;
    }
}

@media (max-width: 580px) {
    .quick-access .active {
        border-color: #0000009c !important;
        box-shadow: inset 0 0 11px #000000bf !important;
        color: #ffffff !important;
    }

    .quick-access > div > :last-child {
        display: none;
    }
}
