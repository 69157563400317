.dropdown-mega-menu-md .btn.card-box:hover {
  transform: scale(1.1);
  z-index: 9;
  box-shadow: 0 5rem 14rem 0 hsla(0, 0%, 100%, .3), 0 0.8rem 2.3rem rgba(0, 0, 0, .6), 0 0.2rem 0.3rem rgba(0, 0, 0, .45);
}

.menu-apps-popover .popover.show.bs-popover-auto {
  border-radius: 17px;
  background: transparent;
}

.text-divider {
  font-size: 14px;
  font-weight: bold;
}

.popover-global-search > .popover {
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.global-search {
  padding: 20px 20px 0 20px;
  min-width: 414px;
}

.global-search ul {
  padding: 0 0 0 10px;
  border-left: dotted 1px #333;
  margin-left: 10px;
}

.global-search ul li {
  margin-left: 5px;
  padding: 3px 0;
}

.global-search > ul {
  margin-left: 0;
}

.global-search button {
  padding: 0;
  width: 100%;
  text-align: left;
}

.header-company-button {
  transition: all .2s ease-out;
  border: none;
  background: transparent;
}

.header-company-button:hover {
  transform: translateY(-2px);
}

.header-company-option {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
}

.header-company-option .title {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-company-option .subtitle {
  display: inline-block;
  width: 100%;
}

#toggle-processos {
  color: #3b3e66;
}

.dropdown-toggle-user, .dropdown-toggle-user:focus {
  background-color: transparent !important;
  border-color: transparent;
}

#alertsPopover,
#menuAppsPopover,
#open-crisp,
#user-dropdown-config,
#open-videos {
  background: #253053;
  border-color: #253053;
  color: #FEFEFF;
}

#alertsPopover:hover,
#menuAppsPopover:hover,
#open-crisp:hover,
#user-dropdown-config:hover,
#open-videos:hover {
  background: #3c44b1;
  border-color: #3c44b1;
}

#alertsPopover:active,
.notification-active,
#menuAppsPopover:active,
.menu-apps-active,
#open-crisp:active,
#open-crisp:focus,
#user-dropdown-config:active,
#user-dropdown-config:focus,
#open-videos:active,
.dropdown-toggle-user:active > #user-dropdown-config,
.user-box.dropdown.show button div {
  background: #2C87F2 !important;
  border-color: #2C87F2 !important;
}

.app-header-menu > div > button:active,
.company-selector-active,
#companySelectorPopover:active {
  color: #2C87F2;
}

.popover-notification div .arrow:after {
  border-bottom-color: #253053 !important;
}

.company-box {
  margin-right: 10px;
  max-width: 300px;
}

#companySelectorPopover {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#companySelectorPopover:hover {
  color: #3c44b1;
}

.company-selector {
  min-width: 414px;
  padding: 20px;
}

[class*="label-empresa-mobile-"] {
  display: none;
}

.badge-notification {
  top: -4px !important;
  height: 12px;
  right: -8px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.badge-notification-count {
  margin-top: 3px;
}

.user-box > button {
  background: transparent;
  border: 0;
}

#alertsPopover,
#menuAppsPopover,
#open-crisp,
#open-videos,
.settings-mobile {
  height: 35px !important;
  line-height: 35px !important;
  width: 35px !important;
  font-size: 13px !important;
}

.only-reference {
  text-align-last: center;
}

.user-apps-mobile > a {
  justify-content: space-between;
  background: #ededf0 !important;
}

.modal-videos .video-frame {
  max-width: 100%;
  padding: 0 50px 30px;
}

.close-modal-videos {
  font-size: 40px;
  line-height: 0;
  padding-top: 0.8rem !important;
}

.container {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-videos .carousel-control-next, .modal-videos .carousel-control-prev {
  width: 3%;
}

.modal-videos .carousel-control-next-icon, .modal-videos .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
  filter: invert(1);
}

.modal-videos .carousel-indicators {
  margin-bottom: -6px;
  filter: invert(1);
}

.cor-modulo-ger {
  color: #3C44B1 !important;
}

.cor-modulo-mfp {
  color: #BD413D !important;
}

.cor-modulo-mcg {
  color: #2C87F2 !important;
}

.cor-modulo-mlf {
  color: #3AB5C7 !important;
}

.cor-modulo-mcp {
  color: #37BD5A !important;
}

.cor-modulo-mho {
  color: #6440AF !important;
}

.cor-modulo-mpr {
  color: #3E6188 !important;
}

.cor-modulo-connect {
  color: #F49D03 !important;
}

.cor-modulo-box {
  color: #7A7B97 !important;
}

.cor-modulo-site {
  color: #222533 !important;
}

.cor-modulo-mic {
  color: #068373 !important;
}

.popover-apps .popover-inner {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

@media (max-width: 440px) {
  [data-testid="toggleMenuModulos"] {
    padding: .25rem .7rem;
    margin-right: 5px !important;
    font-size: 12px;
  }

  .user-box {
    margin-left: 0 !important;
  }

  .app-header--pane #companySelectorPopover, .app-header--pane #alertsPopover, .app-header--pane #menuAppsPopover {
    height: 30px;
    line-height: 30px;
    width: 30px;
  }

  .app-header--pane #companySelectorPopover svg, .app-header--pane #alertsPopover svg, .app-header--pane #menuAppsPopover svg {
    font-size: 18px;
  }

  .app-header--pane .d-inline-block.pr-2 {
    padding-right: .2rem !important;
  }

  .dropdown-modulos .dropdown-mega-menu-md {
    width: 88vw;
  }

  .company-selector {
    width: calc(100vw - 30px);
    min-width: auto;
  }

  .app-wrapper:not(.search-wrapper-open) .app-header .app-header--pane:first-child {
    width: 80px;
  }

  .header-search-wrapper {
    padding: .28571rem .1rem;
  }
}

@media (max-width: 500px) {
  .seletor-emp-mobile {
    max-width: 85%;
  }

  .seletor-emp{
    max-width: 75%;
  }
}

@media (min-width: 851px) {
  .user-apps-mobile {
    display: none;
  }
}

@media (min-width: 750px) {
  .referencia-mobile {
    display: none;
  }
}

@media (max-width: 750px) {
  .emp-mobile {
    display: none;
  }

  .header-search-wrapper > .search-wrapper.is-active {
    width: 50vw;
  }

  .popover-custom-emp > .popover.show.bs-popover-auto {
    top: 82px !important;
    left: 40px !important;
    max-width: 100vw;
    width: 95%;
  }

  .referencia-desktop {
    display: none;
  }

  [class*="label-empresa-mobile-"] {
    display: flex;
    align-items: center;
    height: 25px;
    background: #fefeff;
    z-index: 1048;
    width: 100%;
  }

  [class*="label-empresa-mobile-"] > button > .seletor-emp >:first-child {
    font-size: 14px;
  }

  .seletor-emp-mobile > .company-box > div, .referencia-mobile {
    font-size: 13px;
  }

  .app-header {
    box-shadow: none;
  }
}

@media (max-width: 1380px) {
  .settings-desktop {
    display: none;
  }

  .settings-mobile {
    display: inline-block;
  }
}

@media (max-width: 1200px) {
  .app-header-menu {
    display: block;
  }
  .search-wrapper-open .app-header--pane+.app-header--pane {
    display: none;
  }
  .app-sidebar-mobile-open .app-sidebar {
    left: 0 !important;
    box-shadow: 0.46875rem 0 2.1875rem rgb(59 62 102 / 3%), 0.9375rem 0 1.40625rem rgb(59 62 102 / 3%), 0.25rem 0 0.53125rem rgb(59 62 102 / 5%), 0.125rem 0 0.1875rem rgb(59 62 102 / 3%);
  }
  .app-sidebar .toggle-mobile-sidebar-btn {
    display: block;
  }
  .app-sidebar--header .collapse-sidebar-btn, .app-sidebar--header .expand-sidebar-btn {
    display: none;
  }
  #list-favoritos {
    border-left: 1px solid #B8B8B8;
  }
}

@media (max-width: 850px) {
  .hide-btn {
    display: none !important;
  }
}

#list-favoritos, .modulosMobile {
  border-right: 1px solid #B8B8B8;
}

.referencia-mobile {
  min-width: 25%;
}

@media (min-width: 581px) {
  #list-recentes {
    margin-left: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 580px) {
  .btn-group-modulos-rec-fav:hover,
  .btn-group-modulos-rec-fav:focus,
  .btn-group-modulos-rec-fav > button:hover,
  .btn-group-modulos-rec-fav > button:focus {
    transform: none !important;
  }

  .btn-group-modulos-rec-fav > button:active,
  .btn-group-modulos-rec-fav > button:hover {
    border-color: #0000009c !important;
    box-shadow: inset 0 0 11px #000000bf !important;
    color: #ffffff !important;
  }

  #alertsPopover:hover,
  #menuAppsPopover:hover,
  #open-crisp:hover,
  #user-dropdown-config:hover,
  #open-videos:hover {
    border: none;
    box-shadow: inset 0 4px 9px #000000bf !important;
    background: #00000026;
  }

  #alertsPopover:active,
  .notification-active,
  #menuAppsPopover:active,
  .menu-apps-active,
  #open-crisp:active,
  #user-dropdown-config:active,
  #user-dropdown-config:focus,
  #open-videos:active,
  .dropdown-toggle-user:active > #user-dropdown-config,
  .user-box.dropdown.show button div {
    border: none !important;
    box-shadow: inset 0 4px 9px #000000bf !important;
    background: #00000026 !important;
  }

  #alertsPopover:focus,
  #menuAppsPopover:focus {
    box-shadow: none;
  }

  .dropdown-toggle-user:focus {
    box-shadow: none !important;
  }

  .dropdown-toggle-user:active {
    box-shadow: none !important;
  }

  #list-favoritos {
    border-left: 0;
  }

  .popover-notification > .popover {
    width: calc(100% - 18px);
  }

  .user-box > button:hover {
    background: none;
    transition: none;
  }

  #companySelectorPopover:hover, .seletor-emp:hover {
    color: white !important;
  }

  .app-header .hamburger-inner, .app-header .hamburger-inner:after, .app-header .hamburger-inner:before {
    background: #fff !important;
  }

  #alertsPopover,
  #menuAppsPopover,
  #open-crisp,
  #user-dropdown-config,
  #open-videos {
    background: #00000047 !important;
    border-color: #00000007 !important;
    color: #FFF !important;
  }

  .btn-group-modulos-rec-fav {
    background: #00000047;
    border-radius: 10px;
    border: 1px solid #00000007;
  }

  .btn-group-modulos-rec-fav > button, #companySelectorPopover, .header-search-wrapper > div > label > svg {
    color: #fff;
  }

  .header-search-wrapper > .is-active > label > svg {
    color: #000;
  }

  .referencia-mobile > span {
    color: #fff !important;
  }

  .ger-app-header-mobile, .ger-app-header-mobile:hover {
    background: #3C44B1 !important;
  }

  .label-empresa-mobile-ger {
    background: #2b3180fa !important;
  }
}

@media (max-width: 580px) {
  [data-testid="toggleMenuModulos"] {
    padding: .25rem .7rem;
    margin-right: 5px !important;
    font-size: 12px;
  }

  .mfp-app-header-mobile, .mfp-app-header-mobile:hover {
    background: #BD413D !important;
  }

  .label-empresa-mobile-mfp {
    background: #882f2cf5 !important;
  }

  .mcg-app-header-mobile, .mcg-app-header-mobile:hover {
    background: #2C87F2 !important;
  }

  .label-empresa-mobile-mcg {
    background: #2061aff0 !important;
  }

  .mlf-app-header-mobile, .mlf-app-header-mobile:hover {
    background: #3AB5C7 !important;
  }

  .label-empresa-mobile-mlf {
    background: #2a8390f2 !important;
  }

  .mcp-app-header-mobile, .mcp-app-header-mobile:hover {
    background: #37BD5A !important;
  }

  .label-empresa-mobile-mcp {
    background: #288841f2 !important;
  }

  .mpr-app-header-mobile, .mpr-app-header-mobile:hover {
    background: #3E6188 !important;
  }

  .label-empresa-mobile-mpr {
    background: #2d4662f2 !important;
  }

  .mho-app-header-mobile, .mho-app-header-mobile:hover {
    background: #6440AF !important;
  }

  .label-empresa-mobile-mho {
    display: none;
  }

  .connect-app-header-mobile, .connect-app-header-mobile:hover {
    background: #F49D03 !important;
  }

  .label-empresa-mobile-connect {
    display: none;
  }

  .box-app-header-mobile, .box-app-header-mobile:hover {
    background: #7A7B97 !important;
  }

  .label-empresa-mobile-box {
    background: #58596df5 !important;
  }

  .site-app-header-mobile, .site-app-header-mobile:hover {
    background: #222533 !important;
  }

  .label-empresa-mobile-site {
    background: #191b25fa !important;
  }

  .mic-app-header-mobile, .mic-app-header-mobile:hover {
    background: #068373 !important;
  }

  .label-empresa-mobile-mic {
    display: none;
  }
}
