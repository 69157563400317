.networks {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.networks li {
  display: inline-block;
}

.networks li a {
  font-size: 20px;
  color: #152248;
  padding: 10px;
}

.networks li a svg {
  transition: all 0.2s ease-out;
}

.networks li a:hover svg {
  transform: scale(1.1);
}
