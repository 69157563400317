.jobinfo-header .modal-title {
  font-size: 16px;
  font-weight: bold;
}

.cancelar-exec {
  background-color: #F61D32;
  color: #fff;
  border: none;
  width: 140px;
  padding: 2px 0;
  outline: none;
  border-radius: 4px;
}

.cancelar-exec svg {
  margin: 1px 0 0 5px;
  font-size: 16px;
  vertical-align: top;
}

.cancelar-todos {
  background-color: #F61D32;
  color: #fff;
  border-color: #F61D32;
}

.cancelar-todos:hover {
  color: #fff;
}

@media (max-width: 1099px) {
  .cancelar-todos {
    margin-right: 0 !important;
  }

  .left-buttons {
    min-width: 303px;
    width: min-content;
    display: flex;
    flex-direction: column;
    margin-right: 8px !important;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
  }
}
