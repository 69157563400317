@media only screen and (min-width: 1100px) {
  .has-side-dock {
    margin-right: 60px !important;
  }
}

.rbc-current, .rbc-now {
  color: #ffffff;
}

.calima-calendar {
  min-height: 650px;
}

.rbc-event {
  padding: 0;
  background-color: unset;
  position: relative;
  height: 31px;
}

.rbc-today {
  background-color: #4191ff;
}

.rbc-custom-event {
  border: none;
  border-radius: .29rem;
  box-shadow: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 5px 8px;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  width: 100%;
}

.rbc-month-row:hover > div > .rbc-today {
  background-color: #4191ff;
}

.rbc-month-row:hover > div > .rbc-today:hover {
  background: #50a0ff;
}

.modal-full {
  width: 80%;
  height: 90%;
  max-width: unset;
  max-height: unset;
}

.modal-content-full {
  height: inherit;
}

.react-bs-table-no-data {
  text-align: center;
}

@media only screen and (max-width: 576px) {
  .modal-full {
    width: 100%;
  }

  .modal-dialog {
    padding: .5rem;
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .app-sidebar {
    position: fixed !important;
    left: -320px !important;
  }

  .app-content {
    padding-left: 0 !important;
  }

  .app-header {
    left: 0 !important;
    flex-shrink: 0;
    padding: 0 1rem;
  }

  .app-header .toggle-mobile-sidebar-btn {
    display: flex;
  }

  .header-search-wrapper {
    border-left: 1px solid rgba(7, 9, 25, .1);
    border-right: 1px solid transparent;
    padding: .28571rem .5rem;
  }
}

@media screen and (max-width: 50em) {
  .flex-responsive {
    display: inline-block !important;
    margin-bottom: 15px;
  }

  .flex-responsive > div {
    width: 100% !important;
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.app-page-title {
  margin: 0 12px 0.5rem;
  padding: 1rem;
  justify-content: center;
}

.app-page-title--heading h1 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
}

.rbc-toolbar {
  padding: 0 1rem;
}

@media (min-width: 1366px) {
  .rbc-toolbar {
    flex-direction: row;
  }
}

.app-content--inner {
  padding: 0 5px;
}

input.form-control, .form-group .input-group-prepend {
  height: calc(1.5em + .5rem + 2px);
  font-size: .875rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-header {
  padding: 0.25rem 1.25rem;
}

table, .table {
  font-size: 12px !important;
}

.adquirir-button {
  border: none;
  background: transparent;
  text-decoration: underline;
}

@media (min-width: 1100px) {
  .app-header-fixed .app-content {
    padding-top: 68px;
  }

  .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header {
    padding: 1rem 1.5rem 0.75rem 36px;
    margin: 0;
    height: 100%;
  }

  .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header i {
    font-size: 1.3rem;
  }

  .app-sidebar-collapsed .sidebar-header.sidebar-menu-modulos > span {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0;
  }

  .app-sidebar-collapsed .app-sidebar:not(:hover) .sidebar-header.sidebar-menu-modulos > img {
    position: relative;
    right: -17px;
  }

  .app-sidebar-collapsed .app-sidebar:not(.app-sidebar--mini):hover .sidebar-header.sidebar-menu-modulos > span {
    opacity: 1;
    height: auto;
    width: auto;
    overflow: visible;
    visibility: visible;
    padding: 0 0 0 0.66667rem;
    margin-left: -11px;
  }

  .app-sidebar-collapsed .btn-light-dark-mode {
    display: none;
  }

  .app-sidebar-collapsed .app-sidebar--header div.app-sidebar-logo {
    margin-left: 0;
  }

  .app-sidebar-collapsed .app-sidebar:hover .app-sidebar--header div.app-sidebar-logo {
    margin-left: 70px !important;
  }

  .app-sidebar-collapsed .app-sidebar:hover .app-sidebar--header .btn-light-dark-mode {
    display: block;
  }
}

.modal-xxl {
  max-width: 95%;
}

.grid-pagination-desktop {
  margin-top: .25rem;
}

@media (min-width: 992px) {
  .calendario-saindo-tela .rdtPicker {
    right: 0;
  }
}

.tooltip {
  pointer-events: none !important;
}

.modal-open .loading-disabled-dockButtons button {
  pointer-events: none;
  opacity: .65;
  border-color: #f8f9ff;
}

.text-decoration-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

/* Novo padrão dos botões */
.btn-dark,
.btn-info,
.btn-first,
.btn-danger,
.btn-warning,
.btn-success,
.btn-primary {
  font-weight: 600 !important;
}

.process-bar .float-right > button {
  min-width: 95px;
}

.btn-dark {
  color: #FFFFFF !important;
  background: #3E6188 !important;
  border-color: #3E6188 !important;
}
.btn-dark:active {
  background: #2D4661 !important;
  border-color: #2D4661 !important;
}
.btn-dark:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(62 97 136 / 40%), 0 0.0625rem 0.385rem rgb(62 97 136 / 54%);
}

.btn-info {
  color: #FFFFFF !important;
  background: #253053 !important;
  border-color: #253053 !important;
}
.btn-info:active {
  background: #3B3E66 !important;
  border-color: #3B3E66 !important;
}
.btn-info:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(37 48 83 / 40%), 0 0.0625rem 0.385rem rgb(37 48 83 / 54%);
}

.btn-first,
.btn-warning {
  color: #FFFFFF !important;
  background: #2C87F2 !important;
  border-color: #2C87F2 !important;
}
.btn-first:active,
.btn-warning:active {
  background: #2368B8 !important;
  border-color: #2368B8 !important;
}
.btn-first:not(:disabled):focus,
.btn-warning:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(44 135 242 / 40%), 0 0.0625rem 0.385rem rgb(44 135 242 / 54%);
}

.btn-danger {
  color: #253053 !important;
  background: #C0C3CA !important;
  border-color: #C0C3CA !important;
}
.btn-danger:active {
  background: #AFB2B9 !important;
  border-color: #AFB2B9 !important;
}
.btn-danger:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(192 195 202 / 40%), 0 0.0625rem 0.385rem rgb(192 195 202 / 54%);
}

.btn-success {
  color: #FFFFFF !important;
  background: #253053 !important;
  border-color: #253053 !important;
}
.btn-success:active {
  background: #1A2139 !important;
  border-color: #1A2139 !important;
}
.btn-success:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(37 48 83 / 40%), 0 0.0625rem 0.385rem rgb(37 48 83 / 54%);
}

.btn-primary {
  color: #FFFFFF !important;
  background: #3C44B1 !important;
  border-color: #3C44B1 !important;
}
.btn-primary:active {
  background: #272C6C !important;
  border-color: #272C6C !important;
}
.btn-primary:not(:disabled):focus {
  box-shadow: 0 0.22rem 0.525rem rgb(60 68 177 / 40%), 0 0.0625rem 0.385rem rgb(60 68 177 / 54%);
}

.btn-outline-danger:hover,
.btn-outline-success:hover,
.btn-outline-warning:hover,
.btn-outline-danger:active,
.btn-outline-success:active,
.btn-outline-warning:active {
  background: #E4E4E4 !important;
  border-color: #E4E4E4 !important;
}

.btn-outline-success,
.btn-link.text-primary,
.btn-link.text-primary:hover,
.btn-link.text-success,
.btn-link.text-success:hover {
  color: #253053 !important;
}

.btn-outline-warning,
.btn-link.text-warning,
.btn-link.text-warning:hover,
a.text-warning:focus {
  color: #3C44B1 !important;
}

.btn-outline-danger,
.btn-link.text-danger {
  color: #C0C3CA !important;
}

.btn-outline-danger:hover,
.btn-link.text-danger:hover {
  color: #F61D32 !important;
}

.promo-section-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;
  display: block;
  height: 100vh;
  transition: all .5s cubic-bezier(.685,.0473,.346,1);
}

.gap-10 {
  gap: 10px;
}

.text-blue {
  color: #4191FF;
}

.text-grey {
  color: #C0C3CA;
}

.bg-aviso {
    background-color: #FFE5D7 !important;
}

@media (max-width: 1099px) {
  .modal-dialog .modal-content .card-body .grid-wrapper .action-bar .action-bar-search-box {
    flex-grow: 0;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }

  .modal-dialog .modal-content .card-body .grid-wrapper .grid-pagination-desktop div {
    flex-direction: column-reverse;
  }

  .modal-dialog .modal-content .card-body .grid-wrapper .grid-pagination-desktop div .rc-pagination .rc-pagination-options {
    display: none;
  }

  .modal-dialog .modal-content .card-body .grid-wrapper .grid-pagination-desktop div .grid-export{
    display: none;
  }
}

.app-sidebar--userbox {
  margin: 1rem 1.5rem 1rem;
}

.aviso-inline {
  display: inline-block;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: #7A7B97;
  margin-bottom: 15px;
}

.aviso-inline .svg-box {
  color: #3C44B1 !important;
  font-size: 20px;
  margin-right: 10px;
}

.aviso-inline.warning svg {
  color: #F4762D;
}

.aviso-inline svg {
  font-size: 20px;
  margin-right: 10px;
}

.advanced-search-button {
  margin-left: -5px;
}

