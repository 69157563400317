.company-selector .dropdown-filtros {
  position: absolute;
  top: 10px;
  right: 54px;
}

.company-selector .dropdown-filtros .todos span {
  border-bottom: 1px solid #D6D6DB;
  min-width: 50%;
}

.company-selector .dropdown-filtros .nav-item.active .nav-link {
  color: #3C44B1;
}

.header-company-loading {
  position: absolute;
  top: -21px;
  left: 44%;
}

.header-company-loading span {
  width: 1.5em;
  height: 1.5em;
}

#company-selector-input input {
  max-width: 185px;
}
