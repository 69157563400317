.button-header-app {
  width: 100px;
  min-height: 80px;
  margin: 10px 0;
  padding: 0;
  background: transparent;
  vertical-align: top;
  border: none;
  text-align: center;
}

.button-header-app:hover {
  transform: none !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.button-header-app:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.button-header-app .icon {
  width: 68px;
  height: 65px;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
  transition: background .25s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.button-header-app:hover .icon {
  background: #3C44B1;
  color: #fff;
}

.button-header-app.disabled {
  cursor: not-allowed;
  background: none;
}

.button-header-app.disabled .icon {
  box-shadow: none;
  background: #D6D6D6;
  color: white;
}

.button-header-app.disabled:hover .icon {
  background: #D6D6D6;
  color: white;
}

.button-header-app span {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 3px;
  text-shadow: 0 0 rgba(0, 0, 0, 0.7);
}

.button-connect:hover > div {
  background: #F49D03 !important;
}

.button-box:hover > div {
  background: #7A7B97 !important;
}

.button-site:hover > div {
  background: #222533 !important;
}

.button-nitrus:hover > div {
  background: #6440AF !important;
}

.button-mic:hover > div {
  background: #068373 !important;
}

.button-connect:hover > div > img, .button-box:hover > div > img, .button-site:hover > div > img, .button-nitrus:hover > div > img, .button-mic:hover > div > img {
  filter: brightness(100);
}

.outros_produtos > button > span {
  color: #fff;
}
